import { defineStore } from 'pinia'
import { ref } from '#imports'

export const useSignInStore = defineStore('signin', () => {
  const showSignIn = ref<boolean>(false)
  const step = ref<'signin' | 'welcome'>('signin')

  const setShowSignIn = (value: boolean) => {
    showSignIn.value = value
  }

  const setStep = (value: 'signin' | 'welcome') => {
    step.value = value
  }

  return {
    showSignIn,
    step,
    setStep,
    setShowSignIn,
  }
})
